/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');


:root {
  --status-close: #c91919;
  --status-active: #31c919;
  --status-in-process: #e8ae0b;
}

* {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
}

.linked-text {
  color: #0094FF;
  cursor: pointer;
}

.title-logo {
  width: 100%;
  color: white;
  font-size: 30px;
  font-family: "Titan One", sans-serif;
}

nz-avatar {
  svg {
    vertical-align: baseline !important;
  }
}

.secondary-button {
  &.ant-btn-primary {
    background-color: #20A80A;
    border-color: #20A80A;
  }

  &:hover:not([disabled]) {
    background-color: lighten(#20A80A, 10%);
    border-color: lighten(#20A80A, 10%);
  }

  &[disabled] {
    border-color: #d9d9d9;
    background-color: #f5f5f5;
  }
}

.third-button {
  &.ant-btn-primary {
    background-color: #7E808D;
    border-color: #7E808D;
  }

  &:hover:not([disabled]) {
    background-color: lighten(#7E808D, 10%);
    border-color: lighten(#7E808D, 10%);
  }

  &[disabled] {
    border-color: #d9d9d9;
    background-color: #f5f5f5;
  }
}

.ant-table-thead>tr>th {
  &.text-center {
    text-align: center;
  }
}

nz-table {
  border: 1px solid #000000;

  .ant-table-pagination.ant-pagination {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.required::after {
  content: '*';
  color: red;
}

nz-select {
  &.ant-select-single .ant-select-selector {
    border: 1px solid black !important;
    border-radius: 0.25rem !important;
  }
}

.show-disabled {
  &.ant-input[disabled] {
    border-color: black !important;
    color: black !important;
    background-color: white !important;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: white !important;
    color: black !important;
  }
}

.badge-image {
  width: 30px;
  height: 30px;
}